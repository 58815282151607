// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-list-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\templates\\blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-recruit-list-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\templates\\recruit-list.js" /* webpackChunkName: "component---src-templates-recruit-list-js" */),
  "component---src-templates-blog-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\templates\\blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-recruit-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\templates\\recruit.js" /* webpackChunkName: "component---src-templates-recruit-js" */),
  "component---src-templates-basepage-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\templates\\basepage.js" /* webpackChunkName: "component---src-templates-basepage-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\user\\gatsby-starter-elemental\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

